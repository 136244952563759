import "styles/app.scss";
import "styles/global.scss";
import { Provider } from "react-redux";
import store from "../src/redux/store";
import { Link } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, history } from "./redux/store";
import Home from "pages";

function App(): JSX.Element {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <div className="App">
          <Home history={history} />
        </div>
      </PersistGate>
    </Provider>
  );
}
export { history, Link };
export default App;
