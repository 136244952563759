/* eslint-disable no-var */
import React from "react";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-phone-input-2/lib/style.css";

import "../../src/css/css3/all3.css";
import "../../src/css/css3/fontawesome.min3.css";
import "../../src/css/css3/style3.css";
import "../../src/css/css3/style-admin3.css";
import "../../src/css/css3/all.min3.css";
import "../../src/css/css3/fontawesome3.css";

import "../../src/css/css/all1.css";
import "../../src/css/css/fontawesome.min1.css";
import "../../src/css/css/style1.css";
import "../../src/css/css/style-admin1.css";
import "../../src/css/css/bootstrap1.css";
import "../../src/css/css/fontawesome1.css";

import "../../src/css/css2/all2.css";
import "../../src/css/css2/fontawesome.min2.css";
import "../../src/css/css2/style2.css";
import "../../src/css/css2/style-admin2.css";
import "../../src/css/css2/all.min2.css";
import "../../src/css/css2/fontawesome2.css";

import "../../src/css/global.css";

import { useSelector } from "react-redux";
import { checkToken } from "../services/common.api";
import RegisterWEST from "./registerWEST";
interface IMyProps {
  history: any;
}
const Home: React.FC<IMyProps> = ({ history }) => {
  const usrAuth = useSelector((state: any) => state.userReducer.User_Auth); 
  const tkn = checkToken();
  const isAuth =  (usrAuth || tkn)? true:false;
  
  return (
    <>
      <div className="d-flex flex-column min-vh-100">
        <Router history={history}>
          <Switch>
            {isAuth == true ? (
              <Route
                exact
                path="/"
                render={() => <Redirect to="/RegisterWEST" />}
              />
            ) : (
              <Route exact path="/" render={() => <Redirect to="/RegisterWEST" />} />
            )}

            <Route exact path="/RegisterWEST">
              <RegisterWEST  />
            </Route>
            
          </Switch>
        </Router>
      </div>
    </>
  );
};

export default Home;
