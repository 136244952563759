import { Amplify } from 'aws-amplify'

Amplify.configure({
  Auth: {
    region: "ca-central-1",
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
  },
  Storage: {
    region: "ca-central-1",
    bucket: process.env.REACT_APP_BUCKET,
    identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
    customPrefix: { public: '' },
  },
  API:{
    aws_project_region: "ca-central-1",
    aws_appsync_graphqlEndpoint: "https://lughghx225eqnbewhi5rg643i4.appsync-api.ca-central-1.amazonaws.com/graphql",
    aws_appsync_region: "ca-central-1",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
    aws_appsync_apiKey: "iwefd3qxhvfy7enhgt74ivcdqy"
  }
})
