import { combineReducers } from "redux";
import { userReducer } from "./user";
import { AppConfig } from "./appConfig";
import { connectRouter } from "connected-react-router";
const reducers = (history) =>
  combineReducers({
    router: connectRouter(history),
    userReducer,
    AppConfig
  });

export default reducers;
