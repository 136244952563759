import style from "./style.module.css";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import RegisterWESTComponent from "components/RegisterWEST";

const RegisterWEST = () => {

  const history = useHistory();
  const [hideAlert, setHideAlert] = useState(false)

  const handleBack = () => {
    setHideAlert(false);
    history.push("/")
  }
  const onCancel = () => {
    setHideAlert(false)

  }

  return (
    <div className={style.div_container}>
      {
        hideAlert == true ?
          <SweetAlert
            success
            title="Signup successful!"
            onConfirm={handleBack}
            onCancel={onCancel}
            // confirmBtnText="Go Login"
          >
            Please check your Email and Validate your Account
          </SweetAlert> : null
      }
      <div className="row" style={{ width: "100%" }}>
        <div className="col-sm-0 col-md-2 col-lg-3 col-xl-4"></div>
        <div className="col-sm-12 col-md-8 col-lg-6 col-xl-4">
          <div >
            <div className={style.div_content}>
              <div className={style.div_image}>
              <div className={style.div_square}>
                <img
                  src="Images/westLogo.png"
                  className={style.imgFluid1}
                  alt="Doccure Login"
                />
              </div>
                <div className={style.div_round}>
                  <img
                    src="Images/logo.png"
                    className={style.imgFluid}
                    alt="Doccure Login"
                  />
                </div>
              </div>
              <div className="container pt-5">
                <RegisterWESTComponent />
              </div>
            </div>
            <div className={`text-center ${style.copy}`}>© 2023 ONRx</div>
          </div>
        </div>
        <div className="col-sm-0 col-md-2 col-lg-3 col-xl-4"></div>
      </div>
    </div>
  );
};

export default RegisterWEST;
