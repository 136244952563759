import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import 'antd/dist/antd.css';
import App from "./App";
import './amplifyConfig'

ReactDOM.render(
    <App />,
  document.getElementById("root")
);

reportWebVitals();
