import { ActionTypes } from "../constants/action-types";

export const setUser = (user) => {
  return {
    type: ActionTypes.SET_USER,
    payload: user,
  };
};
export const setProfileComplate = (status) => {
  return {
    type: ActionTypes.IS_PROFILE_COMPLATE,
    payload: status,
  };
};

export const loadCurrentAccount = () => {
  return {
    type: ActionTypes.LOAD_CURRENT_ACCOUNT,
  };
};

export const authentication = (val) => {
  return {
    type: ActionTypes.AUTHENTICATION,
    payload: val,
  };
};

export const setCognitoUserData = (val) => {
  return {
    type: ActionTypes.SET_COGNITO_USER_DATA,
    payload: val,
  };
};

export const setAppConfig = (val) => {
  return {
    type: ActionTypes.App_Config,
    payload: val,
  };
};

export const setUserToken = (val) => {
  return {
    type: ActionTypes.USER_TOKEN,
    payload: val,
  };
};

